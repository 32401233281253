// Utils
import { cn } from '@repo/utils';
// Styles
import styles from './Switch.module.css';

/**
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string} [props.label=null]
 * @param {string} [props.className=null]
 * @param {boolean} [props.checked=null]
 * @param {boolean} [props.required]
 * @returns {JSX.Element}
 */
function Switch({
  name,
  label = null,
  className = null,
  checked = null,
  required = null,
}) {
  return (
    <div className={cn([styles.wrapper, className])}>
      <label htmlFor={name} className={cn([styles.switch])}>
        <input name={name} id={name} type="checkbox" defaultChecked={checked} required={required} />
        <span aria-label={name} className={cn([styles.slider, styles.round])} />
      </label>
      {label ? <span className={styles.label}>{label}</span> : null}
    </div>
  );
}

export default Switch;
