import { useRef } from 'react';
// Utils
import { cn } from '@repo/utils';
// Components
import styles from './Button.module.css';

/**
 * Button
 * @param {object} params
 * @param {React.ReactElement | string} params.children
 * @param {React.MouseEventHandler<HTMLButtonElement>} [params.onClick=() => {}]
 * @param {('yellow' | 'orange')} [params.color='orange']
 * @param {('filled' | 'bordered')} [params.variant='bordered']
 * @param {('regular' | 'small')} [params.size='regular']
 * @param {string} [params.className]
 * @param {boolean} [params.disabled=false]
 * @param {'button'| 'submit'} [params.type='button']
 * @returns {JSX.Element}
 */
function Button({
  children,
  onClick = () => {},
  color = 'yellow',
  variant = 'bordered',
  size = 'regular',
  type = 'button',
  disabled = false,
  className,
}) {
  const buttonRef = useRef();
  const buttonBackgroundRef = useRef();

  /**
   * Updates the background position of the button based on the mouse event.
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The mouse event object.
   */
  const handleUpdateBackgroundPosition = (event) => {
    /**
     * @type {HTMLButtonElement}
     */
    const buttonElement = buttonRef.current;
    /**
     * @type {HTMLButtonElement}
     */
    const backgroundElement = buttonBackgroundRef.current;

    if (buttonElement && backgroundElement) {
      const buttonWidth = buttonElement.getBoundingClientRect().width;
      const x = event.clientX - buttonElement.getBoundingClientRect().left - (buttonWidth / 2);
      const y = event.clientY - buttonElement.getBoundingClientRect().top - (buttonWidth / 2);
      backgroundElement.style.left = `${x}px`;
      backgroundElement.style.top = `${y}px`;
    }
  };

  return (
    <button
      ref={buttonRef}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={cn([
        styles.button,
        'font-uppercase',
        styles[color],
        styles[variant],
        styles[size],
        className || '',
      ])}
      onMouseMove={handleUpdateBackgroundPosition}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.inner}>
        {children}

      </div>
      <div ref={buttonBackgroundRef} className={styles.background} />
    </button>
  );
}

export default Button;
