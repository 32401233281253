// Utils
import { cn } from '@repo/utils';
// Components
import MixedTitle from '../../molecules/MixedTitle/MixedTitle';
import RevealFadeIn from '../../animations/RevealFadeIn';

import styles from './TextBloc.module.css';

/**
 * Text Bloc
 * @param {object} params
 * @param {string} params.title
 * @param {('black' | 'white')} [params.color='black']
 * @param {('orange' | 'violet' | 'yellow' | 'green')} [params.titleScriptColor='orange']
 * @param {string} params.text
 * @param {('center')} [params.align]
 * @param {React.ReactElement} [params.action]
 * @param {string} [params.className]
 * @returns {JSX.Element}
 */
function TextBloc({
  title,
  color = 'black',
  titleScriptColor = 'orange',
  text,
  align,
  action,
  className,
}) {
  return (
    <div className={cn([styles.bloc, styles[color], align ? styles[align] : '', className || ''])}>
      <RevealFadeIn className={styles.title}>
        <MixedTitle title={title} color={color} scriptColor={titleScriptColor} />
      </RevealFadeIn>

      <RevealFadeIn delay={0.2}>
        <p
          className={styles.text}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </RevealFadeIn>

      {action ? (
        <RevealFadeIn className={styles.action} delay={0.4}>
          {action}
        </RevealFadeIn>
      ) : null}
    </div>
  );
}

export default TextBloc;
