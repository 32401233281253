// Utils
import { cn } from '@repo/utils';
// Styles
import styles from './Message.module.css';

/**
 * Message component
 * @param {object} props
 * @param {string} [props.className=null]
 * @param {string} props.message
 * @returns {JSX.Element}
 */
function Message({ className = null, message }) {
  return (
    <div className={cn([styles.message, className])}>{message}</div>
  );
}

export default Message;
