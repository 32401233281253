// Utils
import { cn } from '@repo/utils';
// Styles
import styles from './FileSelector.module.css';

/**
 * Select dropdown list component
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.name
 * @param {React.ChangeEventHandler<HTMLInputElement>} [props.onChange=() => {}]
 * @param {string} [props.imagePreview=null]
 * @param {string} [props.className=null]
 * @param {string} [props.accept=null]
 * @param {string} [props.defaultValue]
 * @returns {JSX.Element}
 */
function FileSelector({
  label,
  name,
  onChange = () => {},
  imagePreview = null,
  className = null,
  accept = null,
  defaultValue = null,
}) {
  return (
    <label className={cn([styles.wrapper, className])} htmlFor="name">
      {!imagePreview
        ? <span className={styles.label}>{label}</span>
        : <img src={imagePreview} alt="Preview" className={styles.preview} />}
      <input
        type="file"
        name={name}
        className={styles.input}
        onChange={onChange}
        accept={accept}
        defaultValue={defaultValue}
      />
    </label>
  );
}

export default FileSelector;
