import { cn } from '@repo/utils';
// Components
import Button from '../../atoms/Button/Button';
// Styles
import styles from './PhoneBloc.module.css';

/**
 * Phone bloc component
 * @param {object} props
 * @param {string} props.text
 * @param {string} props.phone
 * @param {string} [props.className=null]
 * @returns {JSX.Element}
 */
function PhoneBloc({ text, phone, className = null }) {
  // Handlers
  const handleNumberClick = () => window.open(`tel:${phone.replace(/\s/g, '')}`);

  return (
    <div className={cn([styles.wrapper, className])}>
      <p className={styles.text}>{text}</p>
      <Button
        className={styles['added-button']}
        color="orange"
        variant="bordered"
        size="regular"
        onClick={() => handleNumberClick()}
      >
        {phone}
      </Button>
    </div>
  );
}

export default PhoneBloc;
