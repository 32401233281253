import {
  animate, easeInOut, inView,
} from 'framer-motion';
import { useEffect, useRef } from 'react';
import { cn } from '@repo/utils';

import styles from './animations.module.css';

const config = {
  type: 'spring',
  duration: 0.6,
  ease: easeInOut,
};

/**
 * Reveal fade in
 * @param {object} params
 * @param {React.ReactElement} params.children
 * @param {string} [params.className='']
 * @param {number} [params.delay=0]
 * @param {number} [params.offsetBeforeDisplay=0.2]
 * @param {number} [params.translationDistance=20]
 * @returns {JSX.Element}
 */
function RevealFadeIn({
  children, className = '', delay = 0, offsetBeforeDisplay = 0.2, translationDistance = 20,
}) {
  const ref = useRef();

  useEffect(() => {
    inView(
      ref.current,
      ({ target }) => {
        // @ts-ignore framer-motion type issue
        animate(target, { opacity: 1, y: [translationDistance, 0] }, {
          ...config,
          delay: delay + 0.3,
        });
      },
      {
        amount: offsetBeforeDisplay,
      },
    );
  }, []);

  return (
    <div className={cn([styles.fadein, className])} ref={ref}>
      {children}
    </div>
  );
}

export default RevealFadeIn;
