/* eslint-disable max-len */
import { cn } from '@repo/utils';

import styles from './MorphingBlob.module.css';

const morphs = ['morph1', 'morph2', 'morph3', 'morph4'];

/**
 * Morphing Blob
 * @param {object} params
 * @param {React.ReactElement} [params.children=null]
 * @param {string|number} [params.width='100%']
 * @param {string|number} [params.height='100%']
 * @param {('white' | 'green' | 'blue' | 'violet' | 'orange' | 'darkblue')} [params.background='white']
 * @param {('absolute' | 'relative')} [params.position='absolute']
 * @param {('visible' | 'hidden')} [params.overflow='hidden']
 * @param {string} [params.className]
 * @returns {JSX.Element}
 */
function MorphingBlob({
  children = null,
  width = '100%',
  height = '100%',
  background = 'white',
  position = 'absolute',
  overflow = 'hidden',
  className,
}) {
  return (
    <div
      className={cn([styles.blob, styles[morphs[2]], styles[background], styles[overflow], className || ''])}
      style={{ width, height }}
    >
      <div className={cn([styles.inner, styles[position]])}>
        {children}
      </div>
    </div>
  );
}

export default MorphingBlob;
