import { cn } from '@repo/utils';
import styles from './TextField.module.css';

/**
 * Text Field
 * @param {object} params
 * @param {string} params.name
 * @param {string} params.label
 * @param {number} [params.maxWidth=null]
 * @param {string} [params.className=null]
 * @param {string} [params.type='text']
 * @param {string} [params.pattern]
 * @param {string} [params.defaultValue]
 * @param {boolean} [params.required]
 * @returns {JSX.Element}
 */
function TextField({
  name, label, maxWidth = null, className = null, type = 'text', ...props
}) {
  return (
    <label
      htmlFor={name}
      className={cn([styles.wrapper, className])}
      style={maxWidth ? { maxWidth } : {}}
    >
      <input
        id={name}
        name={name}
        type={type}
        className={styles.input}
        placeholder={label}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />

      <span className={styles.label}>{label}</span>
    </label>
  );
}

export default TextField;
