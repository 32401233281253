import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Local files
import FR from './locales/fr.json';

const resources = {
  fr: { translation: FR },
};

const lng = navigator?.language?.split('-')[0];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng,
    fallbackLng: 'fr',
    debug: false, // set to true if you need to debug
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
