import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Utils
import { cn } from '@repo/utils';
// Components
import DropdownList from '../../molecules/DropdownList/DropdownList';
import TextField from '../../atoms/TextField/TextField';
import Button from '../../atoms/Button/Button';
import Message from '../../atoms/Message/Message';
import FileSelector from '../../atoms/FileSelector/FileSelector';
import Switch from '../../atoms/Switch/Switch';
// Styles
import styles from './Form.module.css';

/**
 * Form component
 * @param {object} props
 * @param {Function} props.onSubmit
 * @param {{label: string, value: string | number}[]} props.missionOptions
 * @param {string} props.submitButtonLabel
 * @param {boolean} [props.disabled=false]
 * @param {boolean} [props.hasSubmissionSucceeded=false]
 * @param {null | Omit<Issue, 'customerId'>} [props.defaultValues=null]
 * @returns {JSX.Element}
 */
function Form({
  onSubmit,
  missionOptions,
  submitButtonLabel,
  disabled = false,
  hasSubmissionSucceeded = false,
  defaultValues = null,
}) {
  // Hooks
  const { t } = useTranslation();

  // States
  const [selectedImage, setSelectedImage] = useState(null);
  const SCOPE_OPTIONS = [
    { label: t('form.options.scope.chooseScopeLabel'), value: '' },
    { label: t('form.options.scope.developments'), value: t('form.options.scope.developments') },
    { label: t('form.options.scope.hosting'), value: t('form.options.scope.hosting') },
    { label: t('form.options.scope.others'), value: t('form.options.scope.others') },
  ];

  const SEVERITY_OPTIONS = [
    { label: t('form.options.severity.chooseSeverityLabel'), value: '' },
    { label: t('form.options.severity.low'), value: t('form.options.severity.low') },
    { label: t('form.options.severity.critical'), value: t('form.options.severity.critical') },
    { label: t('form.options.severity.other'), value: t('form.options.severity.other') },
  ];

  // Handlers
  /**
   * Handle image change
   * @param {React.ChangeEvent<HTMLInputElement>} e
   * @returns {void}
   */
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };

    reader.readAsDataURL(file);
  };
  /**
   * Handle form submit
   * @param {React.FormEvent<HTMLFormElement>} event
   */
  const handleSubmit = (event) => {
    /**
     * @type {HTMLInputElement}
     */
    // @ts-ignore for target as we can't cast the type
    const { target } = event;
    const values = {
      missionId: target.missionId.value,
      scope: target.scope.value,
      severity: target.severity.value,
      subject: target.subject.value,
      description: target.description.value,
      steps: target.steps.value,
      target: target.target.value,
      attachments: target.attachments.files[0],
      isUrgent: target.isUrgent.checked,
      email: target.email.value,
      phone: target.phone.value,
      hasAcceptedCommunication: target.hasAcceptedCommunication.checked,
    };

    event.preventDefault();
    // @ts-ignore for reset as it's not recognized as HTMLFormElement
    event.target.reset();
    setSelectedImage(null);
    onSubmit(values);
  };

  return (
    <form name="newIssue" id="newIssue" className={styles.form} onSubmit={handleSubmit}>
      <h2 className={cn([styles.title, styles['field-gap-high'], styles['row-1'], styles['column-full']])}>
        {t('form.title')}
        {hasSubmissionSucceeded ? <Message className={styles.success} message={t('form.success')} /> : null}
      </h2>

      <DropdownList
        className={cn([styles['field-gap-high'], styles['row-2'], styles['column-full']])}
        name="missionId"
        label={t('form.missionIdLabel')}
        options={missionOptions}
        defaultValue={defaultValues?.missionId.toString()}
        required
      />

      <DropdownList
        className={cn([styles['field-gap-high'], styles['row-3'], styles['column-half']])}
        name="scope"
        label={t('form.scopeLabel')}
        options={SCOPE_OPTIONS}
        defaultValue={defaultValues?.scope}
        required
      />
      <DropdownList
        className={cn([styles['field-gap-high'], styles['row-3'], styles['column-half']])}
        name="severity"
        label={t('form.severityLabel')}
        options={SEVERITY_OPTIONS}
        defaultValue={defaultValues?.severity}
        required
      />

      <TextField
        className={cn([styles['field-gap-low'], styles['row-4'], styles['column-full']])}
        name="subject"
        label={t('form.subjectLabel')}
        type="text"
        defaultValue={defaultValues?.subject}
        required
      />
      <TextField
        className={cn([styles['field-gap-low'], styles['row-5'], styles['column-full']])}
        name="description"
        label={t('form.descriptionLabel')}
        type="text"
        defaultValue={defaultValues?.description}
        required
      />
      <TextField
        className={cn([styles['field-gap-low'], styles['row-6'], styles['column-full']])}
        name="steps"
        label={t('form.stepsLabel')}
        type="text"
        defaultValue={defaultValues?.steps}
        required
      />
      <TextField
        className={cn([styles['field-gap-low'], styles['row-7'], styles['column-full']])}
        name="target"
        label={t('form.targetLabel')}
        type="text"
        defaultValue={defaultValues?.target}
        required
      />
      <FileSelector
        className={cn([styles['field-gap-high'], styles['row-8'], styles['column-full']])}
        name="attachments"
        label={t('form.attachmentsLabel')}
        accept="image/png, image/jpeg, image/jpg, application/pdf"
        onChange={handleImageChange}
        imagePreview={selectedImage}
      />

      <Switch
        className={cn([styles['field-gap-low'], styles['row-9'], styles['column-full']])}
        name="isUrgent"
        label={t('form.isUrgentLabel')}
        checked={defaultValues?.isUrgent}
      />
      <TextField
        className={cn([styles['field-gap-high'], styles['row-10'], styles['column-seventy']])}
        name="email"
        label={t('form.emailLabel')}
        type="email"
        defaultValue={defaultValues?.email}
        required
      />
      <TextField
        className={cn([styles['field-gap-high'], styles['row-10'], styles['column-third']])}
        name="phone"
        label={t('form.phoneLabel')}
        type="text"
        defaultValue={defaultValues?.phone}
        required
      />

      <div className={cn([styles.legal, styles['field-gap-high'], styles['row-11'], styles['column-full']])}>
        <Switch
          className={cn([styles['field-gap-low'], styles['row-9'], styles['column-full']])}
          name="hasAcceptedCommunication"
          checked={defaultValues?.hasAcceptedCommunication}
        />
        <p>{t('form.communicationsLegal')}</p>
      </div>

      <div className={cn([styles.footer, styles['row-12'], styles['column-full']])}>
        <Button
          color="orange"
          variant="bordered"
          size="regular"
          type="submit"
          disabled={disabled}
        >
          {submitButtonLabel}
        </Button>
      </div>
    </form>
  );
}

export default Form;
