import { useTranslation } from 'react-i18next';
// Components
import RevealFadeIn from '../../animations/RevealFadeIn';
import MorphingBlob from '../../atoms/MorphingBlob/MorphingBlob';
import Button from '../../atoms/Button/Button';
// eslint-disable-next-line import/no-unresolved
import Logo from '../../../assets/logo/logo-baseline-white.svg?react';
// Styles
import styles from './Error.module.css';

/**
 * Error template
 * @returns {JSX.Element}
 */
function Error() {
  // Hooks
  const { t } = useTranslation();
  return (
    <main className={styles.wrapper}>
      <RevealFadeIn>
        <MorphingBlob
          className={styles.blob}
          width="60vw"
          height="70vh"
          background="violet"
        >
          <div className={styles.content}>
            <Logo className={styles.logo} />
            <h1 className={styles.title}>{t('error.title')}</h1>
            <p className={styles.text}>{t('error.text')}</p>
            <Button
              className={styles.button}
              color="yellow"
              variant="bordered"
              size="regular"
            >
              {t('app.phone')}
            </Button>
          </div>
        </MorphingBlob>
      </RevealFadeIn>
    </main>
  );
}

export default Error;
