import MorphingBlob from '../../atoms/MorphingBlob/MorphingBlob';
// Styles
import styles from './Footer.module.css';

/**
 * @typedef {object} Link
 * @property {string} href
 * @property {string} label
 */

/**
 * Footer
 * @param {object} props
 * @param {{legal:Link}} props.link
 * @returns {JSX.Element}
 */
function Footer({ link }) {
  return (
    <footer className={styles.footer}>
      <a href={link.legal.href} target="_blank" rel="noreferrer">{link.legal.label}</a>
      <span>{`Copyright © ${new Date().getFullYear()} - ZOL`}</span>
      <MorphingBlob
        width="100vw"
        height="100vh"
        background="violet"
        className={styles.blob}
      />
    </footer>
  );
}

export default Footer;
