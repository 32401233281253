// Utils
import { cn } from '@repo/utils';
// Pictures
// eslint-disable-next-line import/no-unresolved
import ChevronDown from '../../../assets/icons/chevron-down.svg?react';
// Styles
import styles from './DropdownList.module.css';

/**
 * Select dropdown list component
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.name
 * @param {{label: string, value: string|number}[]} props.options
 * @param {string} [props.className=null]
 * @param {string} [props.defaultValue]
 * @param {boolean} [props.required]
 * @returns {JSX.Element}
 */
function DropdownList({
  label, name, options, className = null, ...props
}) {
  return (
    <label className={cn([styles.wrapper, className])} htmlFor="name">
      <span className={styles.label}>{label}</span>
      <select
        id={name}
        className={styles.input}
        name={name}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
      <ChevronDown className={styles.icon} />
    </label>
  );
}

export default DropdownList;
