/**
 * Concatenation of several class names
 * @export
 * @param {Array<string>} classes - Class names to concat
 * @returns {string}
 */
export default function cn(classes) {
  return classes
    .filter(Boolean)
    .join(' ');
}
