// Logo
// eslint-disable-next-line import/no-unresolved
import Logo from '../../../assets/logo/logo-white.svg?react';
// Styles
import styles from './Header.module.css';

/**
 * Header component
 * @returns {JSX.Element}
 */
function Header() {
  return (
    <header className={styles.header}>
      <Logo className={styles.logo} />
    </header>
  );
}

export default Header;
