import { cn } from '@repo/utils';

import styles from './MixedTitle.module.css';

/**
 * Mixed Title
 * @param {object} params
 * @param {string} params.title
 * @param {('black' | 'white')} [params.color='black']
 * @param {('orange' | 'violet' | 'yellow' | 'green' | 'white')} [params.scriptColor='yellow']
 * @param {string} [params.className='']
 * @returns {*}
 */
function MixedTitle({
  title,
  color = 'black',
  scriptColor = 'yellow',
  className = '',
}) {
  return (
    <h2
      className={cn([styles.title, styles[color], styles[`script_${scriptColor}`], className])}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
}

export default MixedTitle;
